import {
  Component, Output, EventEmitter, Input, OnInit,
  ElementRef, OnDestroy, ChangeDetectorRef, ViewChildren, QueryList, ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators,
  AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, Subscription } from 'rxjs/index';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { AddressBookI } from '@store/modals';
import { EventLoggerService } from '@services/event-logger-service';
import { ActivatedRoute } from '@angular/router';
import { StringHelperService } from '@services/string-helper/string-helper.service';
import {
  commonSpecialCharecterRegex,
  emailRegex,
  numbersAndSpecialCharacterRegex, onlyEnglishAddressRegex,
  onlyEnglishNameRegex,
  specialCharacterAndEmojiRegex,
} from '@shared/address-form/address-validation.constant';
import { AppConfig } from '../../app.config';

const SPECIAL_CHARACTERS: string = 'specialCharacters';
const NON_ENGLISH: string = 'nonEnglish';
const EMPTY_NAME: string = 'emptyName';
const WORD_LIMIT: string = 'wordLimit';

@Component({
  selector: 'address-form',
  templateUrl: './address-form.html',
})

export class AddressFormComponent implements OnInit, OnDestroy {
  formDataFromParent: Partial<AddressBookI> = {};
  states: Array<any>;
  isSaveForm: boolean = false;
  addressEditable: boolean;
  pinCodeError: boolean = false;
  error: any = {};
  user: any;
  @Input('locationLoading') locationLoading: boolean = false;
  @Output('updateLocationInfo') updateLocationInfo: EventEmitter<void> = new EventEmitter<void>();
  @Input()
  set data(data: { [key: string]: any }) {
    if (this.addressForm) return;
    this.formDataFromParent = { ...data };
    this.formDataFromParent.default = true;
  }
  @Output('dataChange') dataChange: EventEmitter<object> = new EventEmitter<object>();
  @Input()
  set editable(editable: boolean) {
    this.addressEditable = editable;
  }
  @Input('isCheckout') isCheckout: boolean = true;
  @Output('valid') valid: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line new-cap
  @ViewChildren('form') form: QueryList<ElementRef>;
  @ViewChild('nameInput') nameInput: ElementRef;
  @ViewChild('numberInput') numberInput: ElementRef;
  @ViewChild('zipCodeInput') zipCodeInput: ElementRef;
  @ViewChild('cityInput') cityInput: ElementRef;
  @ViewChild('buildingDetails') buildingDetails: ElementRef;
  @ViewChild('buildingDetails2') buildingDetails2: ElementRef;
  addressForm: FormGroup;
  subscriptions: Array<Subscription> = [];
  tags: any[];
  editedCount: number = 0;
  pinCodeMap: any = {};
  experiments: any = {};
  addressBookList$: Observable<AddressBookI[]>;
  hideDefaultAddressSwitch: boolean = false;
  showAlternateNo: boolean = false;
  addressFormEnglishOnly: boolean = false;
  addressFieldError: any = {
    contactName: { contactNameError: false, errorType: '' },
    city: { cityError: false, errorType: '' },
    buildingDetails: { buildingDetailsError: false, errorType: '' },
    buildingDetails2: { buildingDetails2Error: false, errorType: '' },
    landmark: { landmarkError: false, errorType: '' },
  };

  constructor(public conn: ConnectionService,
    public broadcast: BroadcastService,
    private appConfig: AppConfig,
    public commonUtilService: CommonUtilityHelper,
    public eventLoggerService: EventLoggerService,
    public changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private stringHelperService: StringHelperService) {
  }

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
    const experiments = await this.conn.findUserActiveExperiments();
    experiments.forEach((exp: any): void => {
      if (exp.key === 'address_form_english_only') {
        this.addressFormEnglishOnly = true;
      }
    });
    this.states = this.appConfig.Shared.States;
    this.fillForm();
    this.subscriptions.push(this.addressForm.valueChanges.subscribe((): void => {
      this.validateForm('', false);
    }));
    this.subscriptions.push(this.addressForm.controls.zipCode.valueChanges.subscribe((zipCode: number): void => {
      if (String(zipCode).length === 6) this.fetchAddressByPincode();
    }));
    await this.fetchAddressBookTags();

    this.route.queryParams.subscribe((params: any): void => {
      // For the first time address entry don't show the switch
      this.hideDefaultAddressSwitch = !!params?.fromCheckout;
    });
  }

  textFieldValidator(fieldName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value }: { value: string } = control;

      // Check for special characters or emojis
      if (specialCharacterAndEmojiRegex.test(value)) {
        this.setFieldError(fieldName, SPECIAL_CHARACTERS);
        return { invalidCharacter: true };
      }

      if (this.addressFormEnglishOnly) {
        // Check for non-English characters
        if (onlyEnglishAddressRegex.test(value)) {
          this.setFieldError(fieldName, NON_ENGLISH);
          return { englishOnly: true };
        }
      }

      // Clear any previous errors
      this.clearFieldError(fieldName);
      return null;
    };
  }

  private setFieldError(fieldName: string, errorType: string): void {
    if (this.addressFieldError[fieldName]) {
      this.addressFieldError[fieldName][`${fieldName}Error`] = true;
      this.addressFieldError[fieldName].errorType = errorType;
    }
  }

  private clearFieldError(fieldName: string): void {
    if (this.addressFieldError[fieldName]) {
      this.addressFieldError[fieldName][`${fieldName}Error`] = false;
      this.addressFieldError[fieldName].errorType = '';
    }
  }

  fillForm(): void {
    // eslint-disable-next-line max-len
    this.addressForm = new FormGroup({
      contactName: new FormControl({ value: this.formDataFromParent.contactName, disabled: false },
        { validators: [this.nameCheckValidator(), Validators.minLength(3)] }),
      userEmail: new FormControl({ value: this.formDataFromParent.userEmail, disabled: false },
        { validators: [Validators.email, Validators.pattern(emailRegex)] }),
      mobileNumber: new FormControl({ value: this.formDataFromParent?.mobileNumber || this.user?.get('MobileNumber'), disabled: false },
        { validators: [Validators.min(1000000000), Validators.max(9999999999)] }),
      alternateNumber: new FormControl({ value: this.formDataFromParent.alternateNumber, disabled: false },
        { validators: [Validators.min(1000000000), Validators.max(9999999999)] }),
      zipCode: new FormControl({ value: this.formDataFromParent.zipCode, disabled: false },
        { validators: [Validators.max(999999), Validators.required] }),
      buildingDetails: new FormControl({
        value: this.stringHelperService.removeSubstringFromEnd(
          this.formDataFromParent?.buildingDetails || '',
          this.formDataFromParent?.buildingDetails2 || '',
        ),
        disabled: false,
      }, { validators: [this.textFieldValidator('buildingDetails')] }),
      buildingDetails2: new FormControl({ value: this.formDataFromParent.buildingDetails2, disabled: false },
        { validators: [this.textFieldValidator('buildingDetails2')] }),
      landmark: new FormControl({ value: this.formDataFromParent.landmark, disabled: false },
        { validators: [this.textFieldValidator('landmark')] }),
      city: new FormControl({ value: this.formDataFromParent.city, disabled: false },
        { validators: [this.textFieldValidator('city')] }),
      state: new FormControl({ value: this.formDataFromParent.state, disabled: false },
        { validators: [this.textFieldValidator('state')] }),
      tag: new FormControl({ value: this.formDataFromParent.tag, disabled: false },
        { validators: [this.textFieldValidator('tag')] }),
    });
    this.changeDetectorRef.detectChanges();
  }

  async fetchAddressBookTags(): Promise<void> {
    this.tags = JSON.parse(JSON.stringify(await this.conn.fetchAddressTags('address_tag')));
    this.tags?.forEach((tag_: any): void => {
      const tag = tag_;
      tag.default = this.conn.getValueOfLanguageString(tag);
    });
    this.tags.sort((element1: any, element2: any): number => {
      const tagOrder = this.appConfig.Shared.AddressBook.Tags;
      return tagOrder.indexOf(element1.en.toLowerCase()) - tagOrder.indexOf(element2.en.toLowerCase());
    });
    if (!this.formDataFromParent.tag) this.selectTag(0);
  }

  async fetchPincodeInfo(zipCode: number): Promise<any> {
    if (this.pinCodeMap[zipCode]) {
      return this.pinCodeMap[zipCode];
    }
    const { userEmail }: Record<string, AbstractControl<any, any>> = this.addressForm.controls;
    // Reactive form is considering empty email as valid, since it is not a required field.
    // So we need to check for undefined email value
    const isEmailValid = userEmail.status === 'VALID' && !!userEmail.value;
    return this.conn.getPinCodeInfo(zipCode, {
      userEmail: isEmailValid ? 'yes' : '',
    });
  }

  public async fetchAddressByPincode(): Promise<void> {
    const { zipCode }: { zipCode: number } = this.addressForm.getRawValue();
    if (zipCode?.toString().length === 6) {
      const pinCodeData = await this.fetchPincodeInfo(zipCode);
      if (pinCodeData) {
        this.pinCodeMap[zipCode] = pinCodeData;
        this.addressForm.controls.state.setValue(this.getFormatted(pinCodeData?.get('state'), true));
        this.addressForm.controls.city.setValue(this.getFormatted(pinCodeData?.get('district')));
        this.eventLoggerService.trackEvent('pincode_lookup_success',
          { username: this.user?.get('username'),
            pincode: this.addressForm.controls.zipCode.value });
      } else {
        this.addressForm.controls.city.setValue('');
        this.eventLoggerService.trackEvent('pincode_lookup_failed',
          { username: this.user?.get('username'),
            pincode: this.addressForm.controls.zipCode.value });
      }
    }
  }

  async validateForm(from?: string, showError: boolean = true): Promise<void> {
    this.isSaveForm = from === 'parent';
    this.pinCodeError = false;
    let errorFieldSpotted = false;
    let missingField;
    this.constructDeliveryAddress();
    this.dataChange.emit({ ...this.formDataFromParent, ...this.addressForm.getRawValue() });
    this.valid.emit(this.addressForm.valid);
    let pinCodeData: any;
    if (this.addressForm.controls.zipCode.value && this.addressForm.controls.zipCode.value.toString().length > 5) {
      pinCodeData = await this.conn.getPinCodeInfo(this.addressForm.controls.zipCode.value);
      if (pinCodeData) {
        this.pinCodeError = this.getFormatted(pinCodeData?.get('state'), true) !== this.getFormatted(this.addressForm.controls.state.value);
        this.eventLoggerService.trackEvent('pincode_lookup_success',
          { username: this.user?.get('username'),
            pincode: this.addressForm.controls.zipCode.value });
      } else {
        this.pinCodeError = true;
        this.eventLoggerService.trackEvent('pincode_lookup_failed',
          { username: this.user?.get('username'),
            pincode: this.addressForm.controls.zipCode.value });
      }
    } else {
      this.pinCodeError = true;
    }
    if (!showError) return;
    Object.keys(this.addressForm.controls).forEach(async (formKey: string): Promise<void> => {
      const [formElement]: Element[] = this.form.first.nativeElement.querySelectorAll(`[formcontrolname=${formKey}]`);
      if (!formElement || errorFieldSpotted) return;
      if (this.addressForm.controls[formKey].status === 'INVALID') {
        errorFieldSpotted = true;
        formElement.setAttribute('error', '');
        missingField = formElement.nextElementSibling?.innerHTML;
        formElement.parentElement.scrollIntoView({ block: 'center' });
      } else if (formKey === 'zipCode' && this.pinCodeError) {
        formElement.setAttribute('error', '');
      } else {
        formElement.removeAttribute('error');
      }
    });
    if (this.pinCodeError) {
      this.zipCodeInput.nativeElement.focus();
    }
    this.handleIfFormInvalidWithFocus();
  }

  handleIfFormInvalidWithFocus(): void {
    if (!this.addressForm.valid) {
      if (this.addressForm.controls.contactName.status === 'INVALID') {
        this.nameInput.nativeElement.focus();
      } else if (this.addressForm.controls.mobileNumber.status === 'INVALID') {
        this.numberInput.nativeElement.focus();
      } else if (this.addressForm.controls.zipCode.status === 'INVALID') {
        this.zipCodeInput.nativeElement.focus();
      } else if (this.addressForm.controls.city.status === 'INVALID') {
        this.cityInput.nativeElement.focus();
      } else if (this.addressForm.controls.buildingDetails.status === 'INVALID') {
        this.buildingDetails.nativeElement.focus();
      } else if (this.addressForm.controls.buildingDetails2.status === 'INVALID') {
        this.buildingDetails2.nativeElement.focus();
      }
    }
  }

  selectTag(tagIndex: number): void {
    this.addressForm.controls.tag.setValue(this.tags[tagIndex]?.default);
  }

  private constructDeliveryAddress(): void {
    const address = [];
    address.push(this.addressForm?.controls.buildingDetails.value);
    address.push(this.addressForm?.controls.city.value);
    address.push(this.addressForm?.controls.state.value);
    address.push(this.addressForm?.controls.zipCode.value);
    const oldAdress = this.formDataFromParent.deliveryAddress;
    this.formDataFromParent.deliveryAddress = address.filter((each: string): boolean => !!each).join(', ');
    if (oldAdress !== this.formDataFromParent.deliveryAddress) {
      this.editedCount += 1;
    }
  }

  nameCheckValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value }: any = control;
      const fieldName: string = 'contactName';

      if (this.addressFormEnglishOnly) {
        // Check for numbers or special characters
        if (numbersAndSpecialCharacterRegex.test(value)) {
          this.setFieldError(fieldName, SPECIAL_CHARACTERS);
          return { message: 'error' };
        }

        // Check for non-English characters
        if (onlyEnglishNameRegex.test(value)) {
          this.setFieldError(fieldName, NON_ENGLISH);
          return { message: 'error' };
        }
      } else if (commonSpecialCharecterRegex.test(value)) {
        this.setFieldError(fieldName, SPECIAL_CHARACTERS);
        return { message: 'error' };
      }

      // Check for empty names
      if (value?.split(' ')?.length > 0) {
        if (value.split(' ')[0] === '') {
          this.setFieldError(fieldName, EMPTY_NAME);
          return { message: 'error' };
        }
      }

      // Limit to three words in the name
      if (value?.split(' ')?.length > 3) {
        this.setFieldError(fieldName, WORD_LIMIT);
        return { message: 'error' };
      }
      this.clearFieldError(fieldName);
      return null;
    };
  }

  getLocationDetails(): any {
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'get-pincode' }));
    this.locationLoading = true;
    this.updateLocationInfo.emit();
  }

  /**
   * This method is used to restrict special characters on key press
   */
  restrictSpecialChars(event: any): any {
    const regex = /^[0-9]+$/;
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
    }
  }

  private getFormatted(name: string, isState: boolean = false): string {
    if (!name) return '';
    if (isState && name.length > 15) return this.truncateStateName(name);
    return name.toLowerCase()
      .split(' ')
      .filter((each: string): boolean => !!each?.length)
      .map((each: string): string => `${each[0]}`.toUpperCase() + each.slice(1))
      .join(' ');
  }

  truncateStateName(name: String): string {
    return `${name.split(' ')[0]}...`;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
  }
}
